<template>
    <v-card
        outlined
        style="min-height: 200px"
    >
        <v-card-title>
            <div class="d-flex align-center">
            {{ person.name }}
            <v-chip v-if="person.tshirtSize" small class="ml-2">
                T-shirt: {{person.tshirtSize}}
            </v-chip>
            <v-btn @click="$emit('clear')" text icon x-small class="ml-2">
                <v-icon>fal fa-times</v-icon>
            </v-btn>
            <p class="ma-3">
                <v-btn v-for="(organisationName, index) in person.crewOrganisationNames"
                       :key="index"
                       x-small
                       @click="$emit('crew-organisation-selected', {name: organisationName})"
                >
                    {{organisationName}}
                </v-btn>
            </p>
            </div>
        </v-card-title>
        <div v-if="availableDates.length > 1"
            class="mx-3 my-0"
            >
            <v-combobox
                label="Datum"
                :items="availableDates"
                v-model="dateFilter"
                dense
                clearable
            ></v-combobox>
        </div>
        <v-list class="mt-0">
            <v-list-item-group :value="selectedShiftId">
                <v-list-item
                    two-line
                    v-for="shift in filteredShifts" :key="shift.shift.id"
                    @click="$emit('shift-selected', {person, shift})"
                    :value="shift.id"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{shift.shift.positionName}} - {{shift.shift.locationName}}</v-list-item-title>
                        <v-list-item-subtitle>{{$luxon(shift.shift.startDatetime, {output: { format: 'med' } })}} t/m {{$luxon(shift.shift.endDatetime, {output: { format: 'med' } })}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon v-if="shift.shift.wristbandLabelId">
                        <v-btn icon @click="printWristband(shift)" color="warning">
                            <v-icon>fal fa-print</v-icon>
                        </v-btn>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <v-banner color="warning" class="ma-3" v-if="person.shifts.length === 0">
            Er zijn geen diensten gevonden.
        </v-banner>
        <div v-if="person.credits">
            <div class="px-3">
                <span class="text-h6">Munten</span>
            </div>
            <v-simple-table>
                    <tbody>
                    <tr v-for="(credits, date) in filteredCredits" :key="date">
                        <td>{{ $luxon(date, { output: { format: 'date_med' } }) }}</td>
                        <td>{{credits}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "PersonShiftList",
    props: {
        person: {
            type: Object,
            required: true
        },
        selectedShift: {
            type: Object,
            required: false
        }
    },
    computed: {
        dateFilter: {
            get() {
                if (!this.availableDates.includes(this.$store.state.crew.dateFilter)) {
                    return null;
                }
                return this.$store.state.crew.dateFilter;
            },
            set(val) {
                this.$store.commit('SET_DATE_FILTER', val);
            }
        },
        availableDates() {
            let dates = [];
            this.person.shifts.forEach(ps => {
                let date = ps.shift.startDatetime.substring(0, 10);
                if (!dates.includes(date)) {
                    dates.push(date);
                }
            })
            dates = dates.sort();
            return dates;
        },
        selectedShiftId() {
            return this.selectedShift?.id
        },
        filteredShifts() {
            if (!this.dateFilter) {
                return this.sortedShifts;
            }
            return this.sortedShifts.filter(ps => {
                let date = ps.shift.startDatetime.substring(0, 10);
                return this.dateFilter === date;
            })
        },
        sortedShifts () {
            let shifts = JSON.parse(JSON.stringify(this.person.shifts))
            return shifts.sort((a, b) => {
                if (a.shift.startDatetime === b.shift.startDatetime) {
                    return a.shift.endDatetime.localeCompare(b.shift.endDatetime);
                } else {
                    return a.shift.startDatetime.localeCompare(b.shift.startDatetime);
                }
            })
        },
        filteredCredits() {
            const credits = this.person.credits;
            if (!this.dateFilter) {
                return credits;
            }

            if (credits[this.dateFilter]) {
                return {[this.dateFilter]: credits[this.dateFilter]}
            } else {
                return {}
            }
        }
    },
    methods: {
        printWristband(shift) {
            return this.$store.dispatch('printPersonShiftWristband', {
                crewEvent: this.crewEvent,
                person: this.person,
                shift
            });
        },
    }
}
</script>

<style scoped>

</style>
