<template>
    <div>
        <v-btn
            text
            color="warning"
            @click.stop="remarksDialog = true"
        >
            Opmerking
        </v-btn>
        <v-dialog
            v-model="remarksDialog"
            :fullscreen="isMobile"
            :hide-overlay="isMobile"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Opmerking toevoegen</span>
                </v-card-title>
                <v-card-text>
                    <v-textarea
                        v-model="remarks"
                        placeholder="Plaats hier opmerkingen"
                        autofocus
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="remarksDialog = false"
                    >
                        Sluiten
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="saveRemarks()"
                    >
                        Opslaan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
// TODO: [crew] disable scanner input
export default {
    name: "RemarksDialog",
    props: {
        person: {
            type: Object,
            required: true
        },
        shift: {
            type: Object,
            required: true
        },
        crewEvent: {
            type: Object,
            required: true
        }
    },
    mounted () {
        this.remarks = this.shift.remarks || '';
    },
    computed: {
        isMobile() {
            return this.$store.state.app.isMobile
        },
        hasRemarks() {
            return !!this.remarks;
        }
    },
    data: () => ({
        remarksDialog: false,
        remarks: null,
    }),
    watch: {
        shift(value) {
            this.remarks = value.remarks || '';
        },
        remarksDialog(val) {
            this.$emit('toggleBarcodeInput', !val);
        }
    },
    methods: {
        saveRemarks() {
            this.$store.dispatch('addPersonRemarks', {
                crewEvent: this.crewEvent,
                person: this.person,
                shift: this.shift,
                remarks: this.remarks
            }).then(person => {
                this.$store.dispatch('showSnackbar', { text: 'Opmerking toegevoegd', color: 'green'})
                this.$emit('updated', person)
            })
            this.remarksDialog = false;
        }
    }
}
</script>

<style scoped>

</style>
