<template>
    <v-list-item>
        <v-list-item-avatar v-if="!showPerson">
            {{item.serialNo}}
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>
                <span v-if="item.choice">{{item.choice}} - </span>
                <code>{{ item.token }}</code>
            </v-list-item-title>
            <v-list-item-subtitle v-if="showPerson">
                <span v-if="person">{{person.name}}</span>
                <span v-else>{{accreditation.supplierName}}</span>
                <span> - #{{item.serialNo}}</span>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon v-if="isPrintable">
            <v-btn icon @click="print" :color="isPickedUp ? 'grey' : 'success'">
                <v-icon>fal fa-print</v-icon>
            </v-btn>
        </v-list-item-icon>
        <v-list-item-icon v-if="canPickup">
            <v-btn icon @click="pickup" :color="isPickedUp ? 'grey' : 'success'">
                <v-icon>fal fa-arrow-alt-from-bottom</v-icon>
            </v-btn>
        </v-list-item-icon>
    </v-list-item>
</template>

<script>
export default {
    name: "AccreditationItem",
    props: {
        item: {
            type: Object,
            required: true
        },
        accreditation: {
            type: Object,
            required: true
        },
        person: {
            type: Object,
            required: false
        },
        accreditationEvent: {
            type: Object,
            required: true
        },
        showPerson: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        facility() {
            return this.$store.getters.getAccreditationFacilityById(this.item.facilityId);
        },
        isPrintable() {
            return !!this.facility.printerLabelId;
        },
        canPickup() {
            return ['choice', 'physical'].includes(this.facility.facilityType);
        },
        isPickedUp() {
            return !!this.item.pickedUpAt;
        }
    },
    methods: {
        print() {
            return this.$store.dispatch('printAccreditationItem', {
                event: this.accreditationEvent,
                item: this.item,
                accreditation: this.accreditation,
                person: this.person
            })
                .then(() => {
                    this.$emit('update:item', this.item);
                });
        },
        pickup() {
            return this.$store.dispatch('pickupAccreditationItem', {
                event: this.accreditationEvent,
                item: this.item,
                accreditation: this.accreditation,
                person: this.person
            })
                .then(() => {
                    this.$emit('update:item', this.item);
                });
        }
    }
}
</script>

<style scoped>

</style>
