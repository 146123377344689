<template>
    <v-container>
        <v-alert
            v-if="printers.length === 0"
            type="error"
        >
            <v-row align="center">
                <v-col class="grow">
                    Er zijn nog geen printers geconfigureerd.
                </v-col>
                <v-col class="shrink">
                    <v-btn :to="{name:'printer_config'}" text>Printers instellen</v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-alert v-else-if="labels.length === 0" type="warning">
            Er zijn geen labels geconfigureerd
        </v-alert>
        <v-card class="mx-auto"
                max-width="600"
                tile
                v-else
        >
            <v-list class="mx-auto" max-width="600">
                <v-list-item v-for="(label, index) in labels" :key="index">
                    <v-list-item-content>
                        <v-list-item-title>{{ label.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="flex-row align-center">
                        <span v-if="getPrinterOverride(label)" class="caption text--disabled mr-2">
                            {{getPrinterOverride(label).name}}
                        </span>
                        <v-btn icon @click="openDefaultPrinterDialog(label)">
                            <v-icon :color="hasPrinterOverride(label) ? 'warning' : 'grey lighten-1'">fal fa-print-search</v-icon>
                        </v-btn>
                        <v-btn icon @click="openPrintDialog(label)">
                            <v-icon color="green">fal fa-print</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>

        <v-dialog
            v-model="printDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5" v-if="selectedLabel">{{ selectedLabel.name }}</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        label="Aantal"
                        required
                        type="number"
                        v-model="quantity"
                    ></v-text-field>
                    <v-select
                        label="Printer"
                        :items="printers"
                        item-text="name"
                        return-object
                        v-model="selectedPrinter"
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="closePrintDialog()"
                    >
                        Sluiten
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="printLabels()"
                    >
                        Print
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="defaultPrinterDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5" v-if="selectedLabel">{{ selectedLabel.name }}</span>
                </v-card-title>
                <v-card-text>
                    <v-select
                        label="Printer"
                        :items="printers"
                        item-text="name"
                        return-object
                        placeholder="Gebruik algemene standaard printer"
                        clearable
                        v-model="selectedPrinter"
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="closeDefaultPrinterDialog()"
                    >
                        Sluiten
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="saveDefaultPrinter()"
                    >
                        Opslaan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "ManualPrint",
    mounted() {
        this.loadLabels();
    },
    data: () => ({
        printDialog: false,
        defaultPrinterDialog: null,
        quantity: 1,
        selectedLabel: null,
        selectedPrinter: null, // TODO: Bijwerken op basis van selectie,
        labels: []
    }),
    computed: {
        organisationId () {
            return parseInt(this.$route.params.organisation_id);
        },
        ...mapState({
            printers: state => state.printers.printers,
        })
    },
    watch: {
        $route() {
            this.loadLabels();
        }
    },
    methods: {
        loadLabels() {
            this.$store.dispatch('loadLabels', { forceReload: true, organisationId: this.organisationId})
                .then(() => { return this.$store.dispatch('getLabelsByOrganisationId', this.organisationId)})
                .then((labels) => {
                    this.labels = labels.sort((a, b) => {
                        let nameA = a.name.toUpperCase();
                        let nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameB > nameA) {
                            return 1;
                        }
                        return 0;
                    })
                })
        },
        openPrintDialog(label) {
            this.selectedLabel = label;
            this.quantity = 1;
            this.printDialog = true;
            this.selectedPrinter = this.$store.getters.getDefaultPrinterForLabel(label, true)
        },
        closePrintDialog() {
            this.printDialog = false;
        },
        printLabels() {
            this.closePrintDialog()
            this.$store.dispatch('addPrintJob', {
                label: this.selectedLabel,
                printer: this.selectedPrinter,
                quantity: this.quantity,
                data: null
            })
        },
        refreshFromHeader() {
            this.loadLabels()
        },
        openDefaultPrinterDialog(label) {
            this.selectedLabel = label;
            this.selectedPrinter = this.$store.getters.getDefaultPrinterForLabel(label, false);
            this.defaultPrinterDialog = true;
        },
        closeDefaultPrinterDialog() {
            this.defaultPrinterDialog = false;
        },
        saveDefaultPrinter() {
            this.$store.dispatch('setDefaultPrinterForLabel', { label: this.selectedLabel, printer: this.selectedPrinter });
            this.closeDefaultPrinterDialog();
        },
        hasPrinterOverride(label) {
            return this.getPrinterOverride(label) !== null;
        },
        getPrinterOverride(label) {
            return this.$store.getters.getDefaultPrinterForLabel(label, false);
        }
    }
}
</script>

<style scoped>

</style>
