<template>
    <v-container fluid class="entry-scan-container pa-0">
        <PrinterWarningBanner v-if="hasPrinterConfig"></PrinterWarningBanner>
        <!-- Andere checks voor printer worden in de banner zelf gedaan -->
        <v-layout fill-height class="d-flex flex-row justify-space-between" pa-0>
            <ScanInput
                ref="scanInput"
                @input="onInput"
                @searchTextChanged="onSearchTextChanged"
                @searchOptionSelected="onSearchOptionSelected"
                :enable-key-input="enableScanKeyInput"
                :search-results="searchResults"
                style="flex: 50%"
                show-search-input
                v-if="isScanInputVisible"
            >
            </ScanInput>

            <div style="flex: 40%;"
                 class="align-self-stretch flex-grow-1 ma-xs-0 ma-md-3 d-flex flex-column"
                 v-if="isSidebarVisible"
            >
                <History
                    v-if="!selectedPerson && !selectedOrganisation"
                    @select="onLogSelect"
                ></History>
                <CrewOrganisation
                    v-else-if="selectedOrganisation"
                    :organisation="selectedOrganisation"
                    @clear="clearSelection"
                ></CrewOrganisation>
                <PersonShiftList
                    v-else-if="selectedPerson"
                    :person="selectedPerson"
                    :selected-shift="selectedShift"
                    @shift-selected="selectPerson($event.person, $event.shift)"
                    @crew-organisation-selected="selectOrganisation($event)"
                    @clear="clearSelection"
                    :class="{'flex-grow-1': !selectedShift}"
                ></PersonShiftList>
                <PersonShift
                    v-if="selectedPerson && selectedShift"
                    :crew-event="crewEvent"
                    :person.sync="selectedPerson"
                    :shift.sync="selectedShift"
                    @clear="clearShift"
                    @toggleBarcodeInput="enableScanKeyInput = $event"
                    @crew-organisation-selected="selectOrganisation($event)"
                    class="flex-grow mt-3"
                    style="overflow-y: visible;"
                ></PersonShift>
                <PersonAidsList
                    v-if="selectedPerson && !selectedShift"
                    :person="selectedPerson"
                    class="flex-grow mt-3"
                    style="overflow-y: visible;"
                ></PersonAidsList>
            </div>

            <v-dialog
                v-model="warningDialog"
                max-width="500"
            >
                <v-card>
                    <v-card-title class="headline">Let op</v-card-title>

                    <v-card-text>
                        De dienst van deze persoon begint pas over 1 uur
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="green darken-1"
                            text
                            @click="warningDialog = false; person=true"
                        >
                            Toch inchecken
                        </v-btn>

                        <v-btn
                            color="green darken-1"
                            text
                            @click="person = false; resetScreen()"
                        >
                            Niet inchecken
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>

<script>
import ScanInput from "@/components/ScanInput";
import History from "@/components/crew/History";
import PersonShiftList from "@/components/crew/PersonShiftList";
import PersonShift from "@/components/crew/PersonShift";
import PrinterWarningBanner from "@/components/layout/PrinterWarningBanner";
import CrewOrganisation from "@/components/crew/CrewOrganisation";
import PersonAidsList from "../../components/crew/PersonAidsList";

let resetTimer;

export default {
    name: "Scan",
    components: {
        PersonAidsList,
        CrewOrganisation, PrinterWarningBanner, PersonShift, PersonShiftList, History, ScanInput
    },
    mounted() {
        this.loadAppInfo();

        this.autoUpdateTimer = window.setInterval(() => {
            this.refresh()
        }, this.$store.state.app.autoUpdateInterval * 1000)

        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = 'hidden'
    },
    destroyed() {
        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = null
    },
    beforeDestroy() {
        window.clearTimeout(this.autoUpdateTimer)
    },
    data: () => ({
        selectedPerson: null,
        selectedShift: null,
        selectedOrganisation: null,
        warningDialog: false,
        app: null,
        isProcessing: false,
        searchText: null,
        searchResults: null,
        enableScanKeyInput: true
    }),
    computed: {
        isScanInputVisible() {
            return !this.isMobile || this.selectedPerson === null;
        },
        isSidebarVisible() {
            return !this.isMobile || this.selectedPerson !== null;
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        crewEventId() {
            return parseInt(this.$route.params.id);
        },
        crewEvent() {
            return this.$store.getters.getCrewEventById(this.crewEventId);
        },
        history() {
            return this.$store.state.crew.history
        },
        hasPrinterConfig() {
            return true;
        }
    },
    watch: {
        $route() {
            this.loadAppInfo()
        }
    },
    methods: {
        loadAppInfo() {
            this.$store.dispatch('getAppInfo', {id: this.$route.params.id, type: 'crew'}).then(app => {
                this.app = app;
                this.$store.commit('SET_HEADER', {
                    title: app.eventName,
                    headerMode: this.$route.meta.headerMode,
                    isPrinterSelectionVisible: this.$route.meta.isPrinterSelectionVisible,
                    isRefreshButtonVisible: true
                })
            })
        },
        refreshFromHeader() {
            this.refresh(true);
        },
        refresh(forceReload) {
            if (this.crewEvent) {
                this.$store.dispatch('syncEventCrew', {
                    crewEvent: this.crewEvent,
                    uploadChanges: true,
                    incremental: !forceReload
                });
            }
        },
        onInput(barcode) {
            this.isProcessing = true;
            this.$store.dispatch('searchPersonBarcode', {crewEventId: this.crewEventId, barcode})
                .then(result => {
                    this.searchResults = null;
                    if (result.shift) {
                        this.selectPerson(result.person, result.shift, true);
                    } else if (result.person) {
                        this.selectPerson(result.person, null, true);
                    } else {
                        this.$refs.scanInput.showErrorStatus(result.message, result.information);
                    }
                })
        },
        onSearchTextChanged(text) {
            if (!text) {
                this.searchResults = [];
                return;
            }

            let promise;
            if (text.length === 11 || (text.length === 6 && text[0] === 'C')) {
                promise = this.$store.dispatch('searchPersonBarcode', {crewEventId: this.crewEventId, barcode: text})
                    .then(result => {
                        if (result.person) {
                            this.selectPerson(result.person, result.shift, false);
                        }
                        return !!result.person
                    })
            } else {
                promise = Promise.resolve(false);
            }

            promise.then((hasPerson) => {
                if (hasPerson) {
                    return;
                }
                this.$store.dispatch('searchCrewPersons', {query: text})
                    .then(persons => {
                        if (!persons || persons.length === 0) {
                            this.searchResults = null;
                            return;
                        }
                        this.searchResults = persons.map(person => {
                            let organisationNames = person.crewOrganisationNames.join(', ');
                            return {
                                id: person.id,
                                title: person.name,
                                subtitle: `#${person.id} - ${organisationNames}`
                            };
                        })
                    });
            })
        },
        onSearchOptionSelected(person) {
            this.$store.dispatch('getCrewPersonInfo', {crewEventId: this.crewEventId, personId: person.id})
                .then(person => {
                    this.selectPerson(person, null, false)
                })
        },
        onLogSelect(log) {
            if (log.person) {
                this.$store.dispatch('getCrewPersonInfo', {crewEventId: this.crewEventId, personId: log.person.id})
                    .then(person => {
                        let shift = null;
                        if (log.shift) {
                            shift = person.shifts.find(s => s.id === log.shift.id);
                        }
                        this.selectPerson(person, shift);
                    })

            }
        },
        selectPerson(person, shift, updateTimeInOut) {
            if (updateTimeInOut) {
                return this.$store.dispatch('processScannedPerson', {crewEvent: this.crewEvent, person, shift})
                    .then(({person, shift, success, message}) => {
                        this.selectedPerson = person;
                        this.selectedShift = shift || null;
                        this.remarks = shift ? shift.remarks : null;

                        if (success) {
                            this.$refs.scanInput.showSuccessStatus(person.name, message);
                        } else {
                            this.$refs.scanInput.showWarningStatus(person.name, message);
                            this.$store.dispatch('addToCrewLog', {person, shift, action: 'warning'});
                        }
                    })
            } else {
                this.selectedPerson = person;
                this.selectedShift = shift || null;
                this.remarks = shift ? shift.remarks : null;
                if (shift) {
                    this.$store.dispatch('addToCrewLog', {person, shift, action: 'select'});
                }
            }
            this.setClearTimer();
        },
        setClearTimer() {
            if (resetTimer) {
                window.clearTimeout(resetTimer);
            }
            resetTimer = window.setTimeout(() => {
                this.clearSelection()
            }, 60000)
        },
        selectOrganisation(organisation) {
            this.selectedOrganisation = organisation
            this.selectedShift = null;
        },
        clearShift() {
            this.selectedShift = null;
        },
        clearSelection() {
            this.selectedOrganisation = null;
            this.selectedPerson = null;
            this.selectedShift = null;
            this.searchResults = null;
            this.$refs.scanInput.clearSearchText();
            window.clearTimeout(resetTimer)
            resetTimer = null
        }
    }
}
</script>
