<template>
    <v-container>
        <v-data-table
            dense
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :server-items-length="totalOrders"
            :loading="loading"
            single-expand
            show-expand
            :expanded.sync="expanded"
            item-key="orderNo"
            :footer-props="{'itemsPerPageOptions': []}"
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Zoek bestelling..."
                    class="mx-4"
                ></v-text-field>
            </template>

            <template v-slot:item.tickets="{ item }">
                {{ item.tickets.length }} / {{ item.validTicketCount }}
            </template>

            <template v-slot:item.orderNo="{ item }">
                <router-link
                    :to="{name: 'entry_order_info', params: { id: item.orderNo}}">
                    {{ item.orderNo }}
                </router-link>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-3">
                    <v-chip
                        v-for="ticket in item.tickets"
                        :key="ticket.barcode"
                        class="mr-3 mb-3"
                        :class="{'green': (ticket.isValid && ticket.scanCount === 0), 'red': (!ticket.isValid || ticket.scanCount >= 1)}"
                        text-color="white"
                    >
                        <v-avatar
                            left
                            class="darken-4"
                            :class="{'green': (ticket.isValid && ticket.scanCount === 0), 'red': (!ticket.isValid || ticket.scanCount >= 1)}"
                        >
                            {{ ticket.serialNo }}
                        </v-avatar>
                        {{ ticket.barcode }}
                    </v-chip>
                </td>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
let refreshTimer;
let searchTimeout;

export default {
    name: "EntryOpenOrders",
    mounted() {
        this.loadAppInfo();
        refreshTimer = window.setInterval(() => {
            this.refresh();
        }, 300000)
    },
    onBeforeDestroy() {
        window.clearInterval(refreshTimer);
    },
    data: () => ({
        app: null,
        expanded: [],
        hasMore: false,
        headers: [
            {text: 'No.', value: 'orderNo', sortable: false},
            {text: 'Naam', value: 'customerName', sortable: false},
            {text: 'Tickets resterend', value: 'tickets', sortable: false},
            {text: '', value: 'data-table-expand'},
        ],
        options: {page: 1, itemsPerPage: 20},
        orders: [],
        totalOrders: 0,
        loading: true,
        search: '',
    }),
    watch: {
        $route() {
            this.loadAppInfo()
        },
        options: {
            handler() {
                this.loadOrders()
            },
            deep: true,
        },
        search() {
            window.clearTimeout(searchTimeout);
            searchTimeout = window.setTimeout(() => {
                this.loadOrders();
            }, 200);
        }
    },
    computed: {
        eventId() {
            return parseInt(this.$route.params.id);
        }
    },
    methods: {
        loadAppInfo() {
            this.$store.dispatch('getAppInfo', {id: this.eventId, type: 'entry_status'}).then(app => {
                this.app = app;
                this.$store.commit('UPDATE_HEADER', {
                    title: app.title,
                    browserTitle: 'Openstaande orders ' + app.title
                })
            }).then(() => {
                this.loadOrders();
            })
        },
        loadOrders() {
            this.loading = true;

            const { /*sortBy, sortDesc, */page, itemsPerPage} = this.options
            this.$store.dispatch('getOpenOrders', {
                eventId: this.eventId,
                maxResults: itemsPerPage,
                page: page,
                search: this.search
            })
                .then(result => {
                    this.orders = result.orders;
                    this.loading = false;
                    this.totalOrders = result.total;
                    this.hasMore = result.hasMore
                })
        },
        async refresh() {
            let scanGroupIds = this.$store.getters.getScanGroupIdsForEventId(this.eventId);

            for (const scanGroupId of scanGroupIds) {
                await this.$store.dispatch('syncScanGroup', {scanGroupId, incremental: true, uploadChanges: false});
            }

            return this.loadOrders();
        },
        refreshFromHeader() {
            this.refresh();
        }
    }
}
</script>

