<template>
    <div class="d-flex flex-column fill-height">
        <v-card
            outlined
            class="mb-3 flex-grow-0 accreditation-card d-flex flex-column"
            style="min-height: 200px;"
        >
            <v-card-title>
                {{ accreditation.supplierName }}
                <v-chip color="error" v-if="accreditation.status === 'deleted'" class="ml-2">Verwijderd</v-chip>

                <v-btn @click="$emit('clear')" text icon x-small class="ml-auto">
                    <v-icon>fal fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>

                <v-alert color="amber lighten-5" v-if="accreditation.internalRemarks">
                    {{ accreditation.internalRemarks }}
                </v-alert>

                <v-text-field
                    label="Naam contactpersoon"
                    :value="accreditation.supplierContactName"
                    prepend-icon="fal fa-user"
                    readonly
                    outlined
                    dense
                    hide-details
                    class="mb-1"
                ></v-text-field>
                <v-text-field
                    label="E-mailadres"
                    :value="accreditation.supplierContactEmail"
                    prepend-icon="fal fa-envelope"
                    readonly
                    outlined
                    dense
                    hide-details
                    class="mb-1"
                ></v-text-field>
                <v-text-field
                    label="Telefoon"
                    :value="accreditation.supplierContactPhone"
                    prepend-icon="fal fa-phone"
                    readonly
                    outlined
                    dense
                    hide-details
                    class="mb-1"
                ></v-text-field>
            </v-card-text>
        </v-card>

        <v-card
            outlined
            class="mb-3 flex-grow-1 flex-shrink-1 persons-card d-flex flex-column"
            style="flex-basis: 50%"
            v-if="persons.length > 0"
        >
            <v-card-title>Personen</v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item
                        v-for="person in persons" :key="person.id"
                        @click="$emit('person-selected', {accreditation, person})"
                    >
                        <v-list-item-content>
                            <v-list-item-title v-if="person.name">{{ person.name }}</v-list-item-title>
                            <v-list-item-title v-else class="font-italic">Naamloos</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>

        <AccreditationItemList
            class="items-card d-flex flex-column flex-grow-1 flex-shrink-1"
            style="flex-basis: 50%"
            :accreditation="accreditation"
            :person="null"
            :accreditation-event="accreditationEvent"
        ></AccreditationItemList>
    </div>
</template>

<script>
import AccreditationItemList from "@/components/accreditation/AccreditationItemList";

export default {
    name: "AccreditationInfo",
    components: {AccreditationItemList},
    mounted() {
        this.update()
    },
    props: {
        accreditation: {
            type: Object,
            required: true
        },
        accreditationEvent: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        persons: [],
    }),
    watch: {
        accreditation() {
            this.update()
        }
    },
    methods: {
        update() {
            this.$store.dispatch('getAccreditationPersons', {
                accreditationId: this.accreditation.id
            }).then(persons => {
                this.persons = persons
            });
        }
    }

}
</script>

<style lang="scss">
.persons-card, .items-card, .accreditation-card {
    overflow-y: hidden;

    .v-card__text {
        overflow-y: scroll
    }

}
</style>
