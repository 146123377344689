<template>
    <v-card>
        <v-card-title>
            {{organisation.name}}

            <v-btn @click="$emit('clear')" text icon x-small class="ml-2">
                <v-icon>fal fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            {{persons.length}} personen, {{personShifts.length}} diensten
        </v-card-text>
        <v-card-actions>
            <v-btn @click="printAll()">Print alle polsbandjes</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "CrewOrganisation",
    props: {
        organisation: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.loadPersons()
    },
    data: () => ({
        persons: []
    }),
    computed: {
        personShifts () {
            let shifts = [];
            this.persons.forEach(person => {
                shifts = [...shifts, ...person.shifts];
            })
            return shifts;
        }
    },
    watch: {
        organisation() {
            this.loadPersons()
        }
    },
    methods: {
        loadPersons() {
            this.$store.dispatch('findCrewByOrganisation', { organisationName: this.organisation.name })
                .then(persons => this.persons = persons)
        },
        printAll() {
            console.log('printAll')
            this.persons.forEach(person => {
                console.log('person', person)
                person.shifts.forEach(shift => {
                    console.log('print', person, shift)
                    this.$store.dispatch('printPersonShiftWristband', { person, shift })
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
