<template>
    <v-container class="pa-0">
        <v-stepper v-model="step" flat>
            <v-stepper-header>
                <v-stepper-step
                    :complete="step > 1"
                    step="1"
                >
                    Selecteer scangroep
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    :complete="step > 2"
                    step="2"
                >
                    Naam
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    :complete="step > 3"
                    step="3"
                >
                    QR-code
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-list
                        v-if="scanGroups.length > 0"
                        two-line
                        >
                            <v-list-item
                                v-for="scanGroup in scanGroups"
                                :key="scanGroup.id"
                                @click="selectScanGroup(scanGroup)"
                                link
                            >
                                <v-list-item-icon>
                                    <v-icon>fal fa-share-square</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ scanGroup.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                    </v-list>
                    <v-card outlined v-else>
                        <v-card-text>
                            Er zijn geen scangroepen binnen dit evenement die je kunt delen.
                        </v-card-text>
                    </v-card>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-card v-if="selectedScanGroup">
                        <v-card-text>
                            <v-text-field
                                :value="selectedScanGroup.title"
                                label="Scangroep"
                                readonly
                            ></v-text-field>
                            <v-text-field
                                v-model="sharedUserName"
                                label="Omschrijving"
                                hint="Je kunt een naam opgeven zodat je later statistieken per scanner/medewerker kunt zien"
                                persistent-hint
                                :disabled="isGenerating"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                text
                                color="primary"
                                @click="generateQrCode"
                                :loading="isGenerating"
                            >Maak QR-code</v-btn>
                            <v-btn
                                @click="step = 1"
                                :disabled="isGenerating"
                                text
                            >Terug</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <div v-if="sharedLinkData && !isCodeClaimed" class="d-flex flex-column fill-height align-center w-100">
                        <v-banner color="warning" class="mb-5">
                            <strong>Let op:</strong> Deze QR-code/link is maar één keer te gebruiken en is maar 1 uur geldig.<br />
                            Genereer voor iedere medewerker een nieuwe link.
                        </v-banner>
                        <qr-code v-if="sharedLinkData && sharedLinkData.url"
                                    :text="sharedLinkData.url"
                                    :size="300"
                                    class="mb-3"
                        ></qr-code>
                        <div class="text-caption mb-3">{{sharedLinkData.code}}</div>
                        <v-btn color="primary" @click="shareLink">Deel link</v-btn>
                    </div>
                    <div v-if="isCodeClaimed" class="d-flex flex-column fill-height align-center w-100">
                        <v-alert color="success">De code is ingesteld.</v-alert>
                        <v-btn color="primary" @click="step = 2">Genereer nog een link</v-btn>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>

<script>
import apiClient from '@/api/apiClient';
import VueQRCodeComponent from 'vue-qrcode-component'

let statusChecker = null;
export default {
    name: "ShareScanGroup",
    components: {
        'qr-code': VueQRCodeComponent
    },
    data: () => ({
        step: 1,
        isGenerating: false,
        selectedScanGroup: null,
        sharedUserName: null,
        sharedLinkData: null,
        isCodeClaimed: false
    }),
    computed: {
        eventId() {
            return parseInt(this.$route.params.event_id);
        },
        datasetKey() {
            return this.$store.getters.appByTypeAndId('share_scan_groups', this.eventId).datasets[0];
        },
        scanGroups() {
            return this.$store.state.apps.apps
                .filter(app => (app.type === 'entry' && app.datasets.includes(this.datasetKey)))
            ;
        },
        eventName() {
            return this.selectedScanGroup.eventName;
        },
        scanGroupName() {
            return this.selectedScanGroup.title;
        }
    },
    beforeDestroy() {
        this.stopStatusChecker();
    },
    methods: {
        selectScanGroup(entryApp) {
            this.selectedScanGroup = entryApp;
            this.step = 2;
        },
        generateQrCode() {
            this.isGenerating = true
            apiClient.post('/scan-groups/'+this.selectedScanGroup.id+'/share', {
                name: this.sharedUserName
            }).then(response => {
                this.sharedLinkData = response.data;
                this.isCodeClaimed = false
                this.step = 3;
                this.startStatusChecker()
            }).finally(() => {
                this.isGenerating = false
            })
        },
        shareLink() {
            if (navigator.canShare) {
                navigator.share({
                    title: 'Scan tickets voor '+this.eventName+' ('+this.scanGroupName+') op je mobiel.',
                    url: this.sharedLinkData.url
                });
            } else {
                const el = document.createElement('textarea');
                el.value = this.sharedLinkData.url;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                return this.$store.dispatch('showSnackbar', { color: 'success', text: 'Link gekopieërd naar klembord'});
            }
        },
        startStatusChecker()
        {
            statusChecker = window.setTimeout(() => {
                this.checkQrcodeStatus();
            }, 1000);
        },
        stopStatusChecker()
        {
            if (statusChecker) {
                window.clearTimeout(statusChecker)
            }
        },
        checkQrcodeStatus() {
            apiClient.get('/scan-groups/'+this.selectedScanGroup.id+'/share/'+this.sharedLinkData.code)
                .then(response => {
                    if (response.data.valid) {
                        // Code has not been claimed yet
                        this.startStatusChecker();
                    } else {
                        // Code is claimed
                        this.isCodeClaimed = true
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>
