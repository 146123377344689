<template>
    <v-card
        outlined
        class="flex-grow-1"
    >
        <v-card-title>
            Faciliteiten

            <v-dialog
                v-model="printAllDialog"
                width="650"
            >
                <template v-slot:activator="{ on, attrs }">

                    <v-btn
                        depressed
                        :color="pickableItems.length >= 1 ? 'primary' : 'secondary'"
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                        :loading="isPrinting !== null"
                        :disabled="isPrinting !== null || items.length === 0"
                    >
                        <v-icon left>fal fa-print</v-icon>
                        Print alles
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Alles afdrukken/ophalen
                    </v-card-title>

                    <v-card-text class="pt-3">
                        Er zijn nog {{ pickableItems.length }} niet-geprinte faciliteit(en).
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="printAllDialog = false"
                        >
                            Sluiten
                        </v-btn>
                        <v-btn
                            color="warning"
                            text
                            @click="printAll(true)"
                            :disabled="isPrinting && (isPrinting !== 'all')"
                            :loading="isPrinting === 'all'"
                        >
                            Print alles opnieuw
                        </v-btn>
                        <v-btn
                            color="success"
                            text
                            @click="printAll(false)"
                            :disabled="pickableItems.length === 0 || (isPrinting && (isPrinting !== 'not-printed'))"
                            :loading="isPrinting === 'not-printed'"
                        >
                            Print niet-geprinte faciliteiten
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-switch
                v-if="!person"
                v-model="includePersonAccreditations"
                label="Incl. personen"
                class="ml-3"
            />
        </v-card-title>
        <v-card-text>
            <v-list dense>
                <v-list-group v-for="itemGroup in itemsPerFacility" :key="itemGroup.facilityId">
                    <template v-slot:activator>
                        <v-list-item-title>
                            {{ itemGroup.facility.name }}
                            <v-chip
                                v-if="itemGroup.items.length === 1 && itemGroup.items[0].choice"
                                small
                                label
                                class="mr-2"
                                color="secondary"
                                outlined
                            >{{itemGroup.items[0].choice}}</v-chip>
                            <v-chip small>{{ itemGroup.items.length }}</v-chip>
                        </v-list-item-title>
                        <v-list-item-icon v-if="isPrintable(itemGroup.facility)">
                            <v-btn icon @click="print(itemGroup)" :color="getGroupColor(itemGroup)">
                                <v-icon>fal fa-print</v-icon>
                            </v-btn>
                        </v-list-item-icon>
                        <v-list-item-icon v-if="canPickup(itemGroup.facility)">
                            <v-btn icon @click="pickup(itemGroup)" :color="getGroupColor(itemGroup)">
                                <v-icon>fal fa-arrow-alt-from-bottom</v-icon>
                            </v-btn>
                        </v-list-item-icon>
                    </template>

                    <AccreditationItem
                        v-for="item in itemGroup.items" :key="item.id"
                        :item="item"
                        :accreditation="accreditation"
                        :person="item.person ?? person"
                        :accreditation-event="accreditationEvent"
                        :show-person="includePersonAccreditations"
                        @update:item="onItemUpdate"
                    ></AccreditationItem>
                </v-list-group>

            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import AccreditationItem from "@/components/accreditation/AccreditationItem";

export default {
    name: "AccreditationItemList",
    components: {AccreditationItem},
    mounted() {
        this.updateItems();
    },
    data: () => ({
        items: [],
        printAllDialog: false,
        printItemGroupDialog: false,
        isPrinting: null,
        includePersonAccreditations: false
    }),
    props: {
        accreditation: {
            type: Object,
            required: true
        },
        person: {
            type: Object,
            required: false
        },
        accreditationEvent: {
            type: Object,
            required: true
        }
    },
    watch: {
        accreditation() {
            this.updateItems();
        },
        person() {
            this.updateItems();
        },
        includePersonAccreditations() {
            this.updateItems()
        }
    },
    computed: {
        itemsPerFacility() {
            let result = [];
            this.items.forEach(item => {
                let facilityId = item.facilityId;

                let index = result.findIndex(f => f.facilityId === facilityId);

                if (index >= 0) {
                    result[index].items.push(item)
                } else {
                    let facility = this.$store.getters.getAccreditationFacilityById(facilityId);
                    result.push({
                        facilityId,
                        facility,
                        items: [item]
                    })
                }
            })

            result = result.sort((a, b) => {
                let groupA = a.facility.groupDisplayOrder;
                let groupB = b.facility.groupDisplayOrder;

                if (groupA !== groupB) {
                    return groupA - groupB;
                }

                let orderA = a.facility.displayOrder;
                let orderB = b.facility.displayOrder;

                return orderA - orderB;
            })
            return result;
        },
        pickableItems() {
            return this.items.filter(item => {
                if (item.pickedUpAt) {
                    return false;
                }

                let facility = this.$store.getters.getAccreditationFacilityById(item.facilityId);
                return this.isPrintable(facility) || this.canPickup(facility);
            })
        }
    },
    methods: {
        updateItems() {
            this.$store.dispatch('getAccreditationItems', {
                accreditationId: this.accreditation.id,
                personId: (this.person) ? this.person.id : null,
                includePersonAccreditations: (this.person) ? false : this.includePersonAccreditations
            }).then(items => {
                this.items = items
            });
        },
        onItemUpdate() {
            this.updateItems()
        },
        isPrintable(facility) {
            return !!facility.printerLabelId;
        },
        canPickup(facility) {
            return ['choice', 'physical'].includes(facility.facilityType);
        },
        getGroupColor(itemGroup) {
            let allPickedUp = itemGroup.items.every(i => !!i.pickedUpAt);

            if (allPickedUp) {
                return 'grey';
            }

            let somePickedUp = itemGroup.items.find(i => !!i.pickedUpAt);
            if (somePickedUp) {
                return 'warning';
            }

            return 'success';
        },
        print(itemGroup) {
            this.isPrinting = 'group';
            return this.$store.dispatch('printAccreditationItems', {
                event: this.accreditationEvent,
                items: itemGroup.items,
                accreditation: this.accreditation,
                person: this.person
            }).then(() => {
                this.updateItems();
            }).finally(() => {
                this.isPrinting = null;
            })
        },
        pickup(itemGroup) {
            Promise.all(itemGroup.items.map(item => {
                return this.$store.dispatch('pickupAccreditationItem', {
                    event: this.accreditationEvent,
                    item: item,
                    accreditation: this.accreditation,
                    person: this.person
                })
            })).then(() => {
                this.updateItems();
            })
        },
        printAll(printPrintedAgain) {
            // Determines which button should be disabled, and which one have a loading state
            this.isPrinting = printPrintedAgain ? 'all' : 'not-printed';

            // Load items that should be printed (all or only unprinted)
            let itemsToProcess = this.items.filter(i => !i.pickedUpAt || printPrintedAgain);

            // If we are printing from a (root) accreditation, and have `include persons` checked, try
            // to group everything per person
            //let shouldGroupByPerson = (!this.person && this.includePersonAccreditations);

            // Loop through all items, printable items will be added to the itemsToPrint array,
            // pickable items will be marked as picked up directly
            let itemsToPrint = {}
            Promise.all(itemsToProcess.map(item => {
                let facility = this.$store.getters.getAccreditationFacilityById(item.facilityId);
                if (this.isPrintable(facility)) {
                    let personId = item.personId ?? '_'; // Group organisation facilities under '_' key
                    if (!itemsToPrint[personId]) {
                        itemsToPrint[personId] = [];
                    }
                    itemsToPrint[personId].push(item)
                } else if (this.canPickup(facility)) {
                    return this.$store.dispatch('pickupAccreditationItem', {
                        event: this.accreditationEvent,
                        item: item,
                        accreditation: this.accreditation,
                        person: this.person
                    })
                } else {
                    return Promise.resolve();
                }
            }))
                .then(() => {
                    if (itemsToPrint) {
                        let printPromises = Object.values(itemsToPrint).map(items => {
                            return this.$store.dispatch('printAccreditationItems', {
                                event: this.accreditationEvent,
                                items: items,
                                accreditation: this.accreditation,
                                person: this.person
                            })
                        });
                        return Promise.all(printPromises);
                    }
                })
                .then(() => {
                    this.printAllDialog = false;
                })
                .finally(() => {
                    this.isPrinting = null;
                    this.updateItems();
                })

        }
    }
}
</script>

<style scoped>

</style>
