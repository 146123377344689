<template>
  <v-container>
    <v-tabs
    v-model="tab"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab>
        <v-icon left>fal fa-print fa-fw</v-icon>
        Printers
      </v-tab>
      <v-tab>
        <v-icon left>fal fa-scroll fa-fw</v-icon>
        Media
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Printer RHSC01</v-list-item-title>
                <v-list-item-subtitle>Online printer</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Printer RHSC02</v-list-item-title>
                <v-list-item-subtitle>Online printer</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>Printer BT01</v-list-item-title>
                <v-list-item-subtitle>Verbonden via Bluetooth</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Medewerker vrijdag</v-list-item-title>
              <v-list-item-subtitle>(automatisch)</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Diner vrijdag</v-list-item-title>
              <v-list-item-subtitle>Printer BT01</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
    export default {
        name: "Scan",
        data () {
            return {
                tab: 0
            }
        },
    }
</script>

<style scoped>

</style>
