<template>
    <v-container>
        <v-row>
            <v-col>
                <v-skeleton-loader type="card" v-if="order === null"></v-skeleton-loader>
                <v-card outlined v-if="order">
                    <v-card-text>
                        <v-btn
                            class="float-right"
                            color="primary"
                            text
                            :href="getOrderBackstageUrl(order.orderNo)"
                            target="_blank"
                        >
                            <v-icon left>fal fa-external-link</v-icon>
                            Open in backstage
                        </v-btn>
                        <p>
                            <span v-if="order.customerCompanyName">
                                <strong>{{ order.customerCompanyName }}</strong><br />
                                {{ order.customerName }}<br />
                            </span>
                            <span v-else>
                                <strong>{{ order.customerName }}</strong><br />
                            </span>
                            {{ order.customerAddressStreet }} {{ order.customerAddressNumber }}
                            {{ order.customerAddressNumberAddition }}<br/>
                            {{ order.customerAddressZipcode }} {{ order.customerAddressCity }}<br/>
                            <br/>
                            {{ order.customerEmailAddress }}<br/>
                            {{ order.customerPhone }}
                        </p>
                        <v-chip>
                            {{ order.channel }}
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="order">
            <v-col cols="12" md="6">
                <v-card outlined>
                    <v-card-title>Toegangsinformatie</v-card-title>
                    <v-card-text>
                        <p v-if="order.entryInformation">{{ order.entryInformation }}</p>
                        <p v-else><em>Geen opmerkingen</em></p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card outlined>
                    <v-card-title>Interne opmerkingen</v-card-title>
                    <v-card-text>
                        <p v-if="order.internalRemarks">{{ order.internalRemarks }}</p>
                        <p v-else><em>Geen opmerkingen</em></p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card outlined v-if="order">
                    <v-card-title>Tickets</v-card-title>
                        <v-list two-line>
                            <template v-for="ticket in order.tickets">
                                <v-divider
                                    :key="`divider-${ticket.id}`"
                                ></v-divider>
                                <v-list-item :key="ticket.id">
                                    <v-list-item-content>
                                        <v-list-item-title class="d-flex align-content-center">
                                            <v-chip
                                                class="mr-2"
                                                :class="{'green': isTicketScannable(ticket), 'red': !isTicketScannable(ticket)}"
                                                text-color="white"
                                            >
                                                <v-avatar
                                                    left
                                                    class="darken-4"
                                                    :class="{'green': isTicketScannable(ticket), 'red': !isTicketScannable(ticket)}"
                                                >
                                                    {{ticket.serialNo}}
                                                </v-avatar>
                                                {{ticket.barcode}}
                                            </v-chip>
                                            <div class="d-inline-flex flex-column justify-center">
                                                <div>{{ticket.item.internalName}}</div>
                                                <div class="grey--text" v-if="ticket.seatDescription">{{ticket.seatDescription}}</div>
                                            </div>

                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn
                                            color="primary"
                                            :disabled="!isTicketScannable(ticket)"
                                            depressed
                                            @click="scanTicket(ticket)"
                                            :loading="scanningTicketIds.includes(ticket.id)"
                                        >SCAN</v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn @click="goBack()" color="primary">
                    <v-icon left>fal fa-chevron-left</v-icon>
                    Terug
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "OrderInfo",
    mounted() {
        this.loadOrder();
    },
    data: () => ({
        order: null,
        scanningTicketIds: []
    }),
    watch: {
        $route() {
            this.loadOrder()
        }
    },
    computed: {
        orderId() {
            return parseInt(this.$route.params.id);
        }
    },
    methods: {
        loadOrder() {
            this.$store.dispatch('getOrderInfo', {id: this.orderId}).then(order => {
                this.order = order;
                this.$store.commit('UPDATE_HEADER', {
                    title: `#${order.orderNo}`,
                    browserTitle: `${order.orderNo} - ${order.customerName}`
                })
            })
        },
        refreshFromHeader() {
            this.refresh();
        },
        getOrderBackstageUrl(orderNo) {
            return process.env.VUE_APP_BACKSTAGE_URL+'bestellingen/'+orderNo;
        },
        goBack() {
            return history.back();
        },
        isTicketScannable(ticket) {
            return (ticket.isValid && ticket.scanCount === 0)
        },
        scanTicket(ticket) {
            this.scanningTicketIds.push(ticket.id);
            this.$store.dispatch('addManualScan', {
                ticket
            }).then(ticket => {
                this.$store.dispatch('showSnackbar', {text: 'Scan toegevoegd'})
                let index = this.order.tickets.findIndex(t => t.barcode === ticket.barcode);
                this.order.tickets[index] = ticket;
            }).catch((e) => {
                console.error(e);

                this.$store.dispatch('showSnackbar', {text: 'Fout bij toevoegen scan'})
            }).finally(() => {
                this.scanningTicketIds = this.scanningTicketIds.filter(t => t !== ticket.id);
            })
        }
    }
}
</script>

