<template>
    <v-banner
        sticky
        single-line
        v-if="isPrinterBannerVisible"
    >
        <v-icon
            slot="icon"
            color="warning"
            size="36"
        >
            fal fa-print-slash
        </v-icon>

        Je hebt nog geen printer ingesteld.

        <template v-slot:actions>
            <v-btn
                color="primary"
                text
                :to="{name:'printer_config'}"
            >
                Printerinstellingen
            </v-btn>
            <v-btn
                color="default"
                text
                @click="dismissedPrinterBanner = true"
            >
                Negeren
            </v-btn>
        </template>
    </v-banner>
</template>

<script>
export default {
    name: "PrinterWarningBanner",
    data: () => ({
        dismissedPrinterBanner: false
    }),
    computed: {
        isPrinterBannerVisible() {
            return this.$store.state.printers.printers.length === 0 && this.dismissedPrinterBanner === false
        }
    }
}
</script>

<style scoped>

</style>
