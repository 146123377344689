<template>
    <v-card
        outlined
    >
        <v-card-title>
            {{ person.name }}

            <v-btn @click="$emit('clear')" text icon x-small class="ml-2">
                <v-icon>fal fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-subtitle>C{{ person.id.toString().padStart(5, '0') }} - {{ person.crewOrganisationNames.join(', ') }}</v-card-subtitle>
        <v-card-text>
            <v-text-field
                label="Functie"
                :value="shift.shift.positionName"
                prepend-icon="fal fa-user"
                readonly
                outlined
                dense
            ></v-text-field>

            <v-text-field
                label="Locatie"
                :value="shift.shift.locationName"
                prepend-icon="fal fa-map-marker"
                readonly
                outlined
                dense
            ></v-text-field>

            <v-text-field
                label="Tijd in"
                :value="$luxon(shift.timeIn, { output: { format: 'med' } }) || ' '"
                prepend-icon="fal fa-sign-in-alt"
                append-icon="fal fa-stopwatch"
                @click:append="onUpdateTimeInClick"
                :hint="`Geplande starttijd: ${shiftStartTime}`"
                persistent-hint
                readonly
                outlined
                dense
                class="mb-3"
            ></v-text-field>

            <v-text-field
                label="Tijd uit"
                :value="$luxon(shift.timeOut, { output: { format: 'med' } }) || ' '"
                prepend-icon="fal fa-sign-out-alt"
                append-icon="fal fa-stopwatch"
                @click:append="onUpdateTimeOutClick"
                :hint="`Geplande eindtijd: ${shiftEndTime}`"
                persistent-hint
                readonly
                outlined
                dense
                class="mb-3"
            ></v-text-field>

            <ul v-if="(shift.aids.length >= 1) || person.tshirtSize" class="body-1 text--black">
                <li v-for="aid in shift.aids" :key="aid.id">{{aid.name}}</li>
                <li v-if="person.tshirtSize">
                    T-Shirt maat: {{ person.tshirtSize }}
                    <span
                        style="background-color: var(--v-secondary-base); border-radius: 4px; padding: 4px; display: inline-block; font-size: 0.7rem;"
                        v-if="shift && shift.isTeamLeader"
                    >Teamleider</span>
                </li>
            </ul>

            <v-alert color="amber lighten-5" v-if="shift.remarks">
                {{ shift.remarks }}
            </v-alert>
        </v-card-text>

        <v-card-actions>
            <v-btn color="primary" @click="clearSelection()">Sluiten</v-btn>
            <RemarksDialog
                :person="person"
                :shift="shift"
                :crew-event="crewEvent"
                @updated="emitUpdate"
                @toggleBarcodeInput="onBarcodeInputToggle"
            ></RemarksDialog>
            <v-btn text color="warning" :disabled="!shift.shift.wristbandLabelId" @click="printWristband()">Print
                polsbandje
            </v-btn>
        </v-card-actions>

        <v-dialog
            v-model="updateTimeInDialog"
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Inchecktijd bijwerken?
                </v-card-title>

                <v-card-text>
                    Deze persoon is al eerder ingecheckt. Weet je zeker dat je de inchecktijd wil bijwerken?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        text
                        @click="updateTimeInDialog = false"
                    >
                        Annuleren
                    </v-btn>

                    <v-btn
                        color="success"
                        text
                        @click="updateTimeInDialog = false; updateTimeIn()"
                    >
                        Tijd bijwerken
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="updateTimeOutDialog"
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Uitchecktijd bijwerken?
                </v-card-title>

                <v-card-text>
                    Deze persoon is al eerder uitgecheckt. Weet je zeker dat je de uitchecktijd wil bijwerken?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        text
                        @click="updateTimeOutDialog = false"
                    >
                        Annuleren
                    </v-btn>

                    <v-btn
                        color="success"
                        text
                        @click="updateTimeOutDialog = false; updateTimeOut()"
                    >
                        Tijd bijwerken
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import RemarksDialog from "@/components/crew/RemarksDialog";

export default {
    name: "PersonShift",
    components: {RemarksDialog},
    props: {
        person: {
            type: Object,
            required: true
        },
        shift: {
            type: Object,
            required: true
        },
        crewEvent: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        updateTimeInDialog: false,
        updateTimeOutDialog: false
    }),
    computed: {
        shiftStartTime() {
            return this.$luxon(this.shift.shift.startDatetime, {output: {format: 'med'}});
        },
        shiftEndTime() {
            return this.$luxon(this.shift.shift.endDatetime, {output: {format: 'med'}});
        },
    },
    methods: {
        onUpdateTimeInClick() {
            if (this.shift.timeIn) {
                this.updateTimeInDialog = true
            } else {
                this.updateTimeIn();
            }
        },
        onUpdateTimeOutClick() {
            if (this.shift.timeOut) {
                this.updateTimeOutDialog = true
            } else {
                this.updateTimeOut();
            }
        },
        updateTimeIn() {
            return this.$store.dispatch('checkInPerson', {
                crewEvent: this.crewEvent,
                person: this.person,
                shift: this.shift
            })
                .then(person => {
                    this.emitUpdate(person, undefined);
                });
        },
        updateTimeOut() {
            return this.$store.dispatch('checkOutPerson', {
                crewEvent: this.crewEvent,
                person: this.person,
                shift: this.shift
            })
                .then(person => {
                    this.emitUpdate(person, undefined);
                });
        },
        emitUpdate(person, shift) {
            if (!shift) {
                shift = person.shifts.find(s => s.id === this.shift.id)
            }
            this.$emit('update:person', person);
            this.$emit('update:shift', shift);
        },
        printWristband() {
            return this.$store.dispatch('printPersonShiftWristband', {
                crewEvent: this.crewEvent,
                person: this.person,
                shift: this.shift,
            });
        },
        clearSelection() {
            this.$emit('clear');
        },
        onBarcodeInputToggle(value) {
            this.$emit('toggleBarcodeInput', value)
        }
    }
}
</script>

<style scoped>

</style>
