<template>
    <v-card
        outlined
        class="fill-height"
    >
        <v-card-title>Geschiedenis</v-card-title>
        <v-list two-line>
            <v-list-item-group>
                <template v-for="(log, index) in history">
                    <v-list-item two-line :key="'log-item-' + index" @click="onLogClick(log)">
                        <v-list-item-icon>
                            <v-icon v-if="log.action === 'print'" color="success">fal fa-print</v-icon>
                            <v-icon v-if="log.action === 'pickup'" color="success">fal fa-arrow-alt-from-bottom</v-icon>
                            <v-icon v-else color="error">fal fa-times</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{log.accreditation.supplierName}}</v-list-item-title>
                            <v-list-item-subtitle v-if="log.message">{{log.message}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-else-if="log.person">{{log.person.name}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>{{$luxon(log.timestamp, {output: { format: 'times' } })}}</v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider
                        v-if="index < history.length - 1"
                        :key="index"
                    ></v-divider>
                </template>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: "History",
    computed: {
        history () {
            return this.$store.state.accreditation.history
        }
    },
    methods: {
        onLogClick(log) {
            this.$emit('select', log)
        }
    }
}
</script>

<style scoped>

</style>
