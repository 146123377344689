<template>
    <v-card
        outlined
    >
        <v-card-title>Geschiedenis</v-card-title>
        <v-list two-line>
            <v-list-item-group>
                <template v-for="(log, index) in history">
                    <v-list-item two-line :key="'log-item-' + index" @click="onLogClick(log)">
                        <v-list-item-icon>
                            <v-icon color="success" v-if="log.action === 'checkin'">fa-sign-in-alt</v-icon>
                            <v-icon color="success" v-else-if="log.action === 'checkout'">fa-sign-out-alt</v-icon>
                            <v-icon color="primary" v-else-if="log.action === 'select'">fal fa-search</v-icon>
                            <v-icon color="warning" v-else-if="log.action === 'warning'">fal fa-exclamation-triangle</v-icon>
                            <v-icon color="error" v-else>fal fa-times</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{log.person.name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="log.message">{{log.message}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-else-if="log.person">{{log.person.crewOrganisationNames.join(', ')}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>{{$luxon(log.timestamp, {output: { format: 'times' } })}}</v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider
                        v-if="index < history.length - 1"
                        :key="index"
                    ></v-divider>
                </template>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: "History",
    computed: {
        history () {
            return this.$store.state.crew.history
        }
    },
    methods: {
        onLogClick(log) {
            this.$emit('select', log)
        }
    }
}
</script>

<style scoped>

</style>
