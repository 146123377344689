<template>
    <v-card
        outlined
        style="min-height: 200px"
    >
        <v-card-title>Middelen</v-card-title>

        <ul class="mx-3 mb-2">
            <li v-for="aid in aidsWithQuantity" :key="aid.id">
                {{aid.quantity}}x
                {{aid.name}}
            </li>
        </ul>
    </v-card>

</template>

<script>
export default {
    name: "PersonAidsList",
    props: {
        person: {
            type: Object,
            required: true
        },
    },
    computed: {
        aidsWithQuantity() {
            // We bouwen eerst een map, met als key het ID van het middel
            // En als value de gegevens van het middel aangevuld met een `quantity`

            // Dit doen we door eerst door de diensten te lopen
            return Object.values(this.person.shifts.reduce((map, shift) => {
                // Dan per dienst de middelen
                return shift.aids.reduce((map, aid) => {
                    if (map[aid.id]) {
                        // Als het id al voorkomt in de map, quantity verhogen
                        map[aid.id].quantity++;
                    } else {
                        // Als het nog niet voorkomt, dan toevoegen, en quantity initialiseren op 1
                        map[aid.id] = {...aid, quantity: 1}
                    }
                    return map;
                }, map)
            }, {}))
                // Vervolgens sorteren op naam
                .sort((a, b) => {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
        }
    }
}
</script>

<style scoped>

</style>
