<template>
    <div class="d-flex flex-column" style="height: 100%">
        <v-card
            outlined
            class="mb-3 flex-grow-0 accreditation-card d-flex flex-column"
            style="min-height: 100px;"
        >
            <v-card-title>
                <span v-if="person.name">{{person.name}}</span>
                <span v-else class="font-italic">Naamloos</span>

                <v-chip color="error" v-if="person.isDeleted" class="ml-2">Verwijderd</v-chip>

                <v-btn @click="$emit('clear')" text icon x-small class="ml-2">
                    <v-icon>fal fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                <v-btn text @click="$emit('accreditation-selected', {accreditation})">{{ accreditation.supplierName }}</v-btn>
            </v-card-subtitle>
            <v-card-text v-if="false">
                status / info
            </v-card-text>
        </v-card>

        <AccreditationItemList
            class="items-card d-flex flex-column"
            :accreditation="accreditation"
            :person="person"
            :accreditation-event="accreditationEvent"
        ></AccreditationItemList>
    </div>
</template>

<script>
import AccreditationItemList from "@/components/accreditation/AccreditationItemList";
export default {
    name: "PersonInfo",
    components: {AccreditationItemList},
    mounted() {
        this.update()
    },
    props: {
        accreditation: {
            type: Object,
            required: true
        },
        person: {
            type: Object,
            required: true
        },
        accreditationEvent: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        items: []
    }),
    watch: {
        accreditation() {
            this.update()
        },
        person() {
            this.update()
        }
    },
    methods: {
        update()
        {
            this.$store.dispatch('getAccreditationItems', {
                accreditationId: this.accreditation.id,
                personUuid: this.person.id
            }).then(items => { this.items = items });
        }
    }
}
</script>

<style scoped>

</style>
