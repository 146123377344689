<template>
    <v-container fluid class="entry-scan-container pa-0">
        <PrinterWarningBanner v-if="hasPrinterConfig"></PrinterWarningBanner>
        <!-- Andere checks voor printer worden in de banner zelf gedaan -->
        <v-layout fill-height class="d-flex flex-row justify-space-between" pa-0>
            <ScanInput
                ref="scanInput"
                @input="onInput"
                @searchTextChanged="onSearchTextChanged"
                @searchOptionSelected="onSearchOptionSelected"
                :search-results="searchResults"
                style="flex: 50%"
                show-search-input
                v-if="isScanInputVisible"
                defaultText="Scan een accreditatie"
            >
            </ScanInput>

            <div style="flex: 40%"
                 class="align-self-stretch flex-grow-1 ma-xs-0 ma-md-3"
                 v-if="isSidebarVisible"
            >
                <History
                    v-if="!selectedAccreditation"
                    @select="onLogSelect"
                ></History>
                <AccreditationInfo
                    v-else-if="selectedAccreditation && !selectedPerson"
                    :accreditation="selectedAccreditation"
                    :accreditation-event="accreditationEvent"
                    @person-selected="selectAccreditation($event.accreditation, $event.person)"
                    @clear="clearSelection"
                ></AccreditationInfo>
                <PersonInfo
                    v-else
                    :accreditation-event="accreditationEvent"
                    :accreditation.sync="selectedAccreditation"
                    :person.sync="selectedPerson"
                    @accreditation-selected="selectAccreditation($event.accreditation, null)"
                    @clear="clearSelection"
                ></PersonInfo>
            </div>
        </v-layout>
    </v-container>
</template>

<script>
import ScanInput from "@/components/ScanInput";
import History from "@/components/accreditation/History";
import PrinterWarningBanner from "@/components/layout/PrinterWarningBanner";
import AccreditationInfo from "@/components/accreditation/AccreditationInfo";
import PersonInfo from "@/components/accreditation/PersonInfo";

export default {
    name: "Scan",
    components: {PersonInfo, AccreditationInfo, PrinterWarningBanner, History, ScanInput},
    mounted() {
        this.loadAppInfo();

        this.autoUpdateTimer = window.setInterval(() => {
            this.refresh()
        }, this.$store.state.app.autoUpdateInterval * 1000)

        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = 'hidden'
    },
    destroyed() {
        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = null
    },
    beforeDestroy() {
        window.clearTimeout(this.autoUpdateTimer)
    },
    data: () => ({
        selectedAccreditation: null,
        selectedPerson: null,
        app: null,
        isProcessing: false,
        searchText: null,
        searchResults: null
    }),
    computed: {
        isScanInputVisible() {
            return !this.isMobile || this.selectedAccreditation === null;
        },
        isSidebarVisible() {
            return !this.isMobile || this.selectedAccreditation !== null;
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        accreditationEventId() {
            return parseInt(this.$route.params.id);
        },
        accreditationEvent() {
            return this.$store.getters.getAccreditationEventById(this.accreditationEventId);
        },
        history() {
            return this.$store.state.accreditation.history
        },
        hasPrinterConfig() {
            return true;
        }
    },
    watch: {
        $route() {
            this.loadAppInfo()
        }
    },
    methods: {
        loadAppInfo() {
            this.$store.dispatch('getAppInfo', {id: this.accreditationEventId, type: 'accreditation'}).then(app => {
                this.app = app;
                this.$store.commit('SET_HEADER', {
                    title: app.eventName,
                    headerMode: this.$route.meta.headerMode,
                    isPrinterSelectionVisible: this.$route.meta.isPrinterSelectionVisible,
                    isRefreshButtonVisible: true
                })
            })
        },
        refreshFromHeader() {
            this.refresh();
        },
        refresh() {
            if (this.accreditationEvent) {
                this.$store.dispatch('syncEventAccreditation', {
                    accreditationEvent: this.accreditationEvent,
                    uploadChanges: true,
                    incremental: true
                });
            }
        },
        onInput(barcode) {
            this.isProcessing = true;
            // TODO: Test
            this.$store.dispatch('searchAccreditationByQrCode', {
                accreditationEventId: this.accreditationEventId,
                barcode
            }).then(result => {
                if (result.accreditation || result.person) {
                    this.$refs.scanInput.showSuccessStatus(result.message, result.information);
                    this.selectAccreditation(result.accreditation, result.person);
                    this.searchResults = null;
                } else if (this.searchResults && this.searchResults.length === 1) {
                    // Select first result
                    let searchResult = this.searchResults[0];
                    this.onSearchOptionSelected(searchResult);
                    this.$refs.scanInput.showSuccessStatus(searchResult.title, searchResult.subtitle);
                } else if (this.searchResults && this.searchResults.length > 1) {
                    this.$refs.scanInput.showWarningStatus(result.message, result.information);
                } else {
                    this.$refs.scanInput.showErrorStatus(result.message, result.information);
                    this.searchResults = null;
                }
            })
        },
        onSearchTextChanged(text) {
            if (!text) {
                this.searchResults = [];
                return;
            }

            this.$store.dispatch('searchAccreditation', {
                query: text,
                accreditationEventId: this.accreditationEventId
            }).then(results => {
                if (!results || results.length === 0) {
                    this.searchResults = null;
                    return;
                }
                this.searchResults = results
            });
        },
        onSearchOptionSelected(option) {

            if (option.person) {
                this.$store.dispatch('getAccreditationPersonInfo', {
                    accreditationEventId: this.accreditationEventId,
                    personId: option.person.id
                }).then(person => {
                    this.selectAccreditation(person.accreditation, person)
                })
            } else {
                this.selectAccreditation(option.accreditation, option.person)
            }
        },
        onLogSelect(log) {
            if (log.person) {
                this.$store.dispatch('getAccreditationPersonInfo', {personId: log.person.id})
                    .then((person) => {
                        this.selectAccreditation(person.accreditation, person);
                    })
            } else {
                this.$store.dispatch('getAccreditationInfo', {accreditationId: log.accreditation.id})
                    .then((accreditation) => {
                        this.selectAccreditation(accreditation, null);
                    })
            }
        },
        selectAccreditation(accreditation, person) {
            this.selectedAccreditation = accreditation || {};
            this.selectedPerson = person || null;
            this.remarks = person ? person.remarks : null;
        },
        clearSelection() {
            this.selectedAccreditation = null;
            this.selectedPerson = null;
            this.searchResults = null;
            this.$refs.scanInput.clearSearchText();
        }
    }
}
</script>
