<template>
    <v-container fluid class="entry-scan-container pa-0">
        <v-layout fill-height class="d-flex flex-row justify-space-between" pa-0>
            <ScanInput ref="scanInput" @input="onInput" style="flex: 50%"></ScanInput>
            <v-card
                class="align-self-stretch flex-grow-1 ma-xs-0 ma-md-3 hidden-sm-and-down"
                outlined
                style="flex: 40%"
            >
                <v-card-title>Geschiedenis</v-card-title>
                <v-list two-line>
                    <v-list-item-group>
                        <template v-for="(log, index) in history">
                            <v-list-item two-line :key="'log-item-' + index">
                                <v-list-item-icon>
                                    <v-icon color="success" v-if="log.result === 'valid'">fal fa-check</v-icon>
                                    <v-icon color="warning" v-else-if="log.result === 'warning'">fal fa-exclamation-triangle</v-icon>
                                    <v-icon color="error" v-else>fal fa-times</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="barcode">{{log.barcode}}</v-list-item-title>
                                    <v-list-item-subtitle>{{log.message}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text>{{$luxon(log.timestamp, {output: { format: 'times' } })}}</v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider
                                v-if="index < history.length - 1"
                                :key="index"
                            ></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>

import ScanInput from "@/components/ScanInput";

export default {
    name: "Scan",
    components: {ScanInput},
    mounted () {
        this.loadAppInfo();

        this.autoUpdateTimer = window.setInterval(() => {
            this.refresh()
        }, this.$store.state.app.autoUpdateInterval * 1000)

        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = 'hidden'
    },
    destroyed () {
        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = null
    },
    beforeDestroy () {
        window.clearTimeout(this.autoUpdateTimer)
    },
    data: () => ({
        app: null,
        isProcessing: false,
    }),
    watch: {
        $route () {
            this.loadAppInfo()
        }
    },
    computed: {
        history () { return this.$store.state.tickets.history },
        scanGroupId () { return parseInt(this.$route.params.id); }
    },
    methods: {
        loadAppInfo() {
            this.$store.dispatch('getAppInfo', { id: this.scanGroupId, type: 'entry' }).then(app => {
                this.app = app;
                this.$store.commit('SET_HEADER', { title: app.title, headerMode: this.$route.meta.headerMode })
            })
        },
        refresh() {
            this.$store.dispatch('syncScanGroup', { scanGroupId: this.scanGroupId, uploadChanges: true, incremental: true });
        },
        onInput (barcode) {
            this.isProcessing = true;
            this.$store.dispatch('scan', { scanGroupId: this.scanGroupId, barcode })
            .then(result => {
                console.log(result);
                switch(result.result) {
                    case 'valid':
                        this.$refs.scanInput.showSuccessStatus(result.message, result.information);
                        break;
                    case 'warning':
                        this.$refs.scanInput.showWarningStatus(result.message, result.information, true);
                        break;
                    case 'error':
                    case 'invalid':
                        this.$refs.scanInput.showErrorStatus(result.message, result.information);
                        break;
                }
            })
        }

    }
}
</script>
