<template>
    <v-container>
        <v-row v-if="scanGroups && scanGroups.length >= 1">
            <v-col>
                <v-expansion-panels multiple :value="expandedPanels">
                    <v-expansion-panel outlined
                        v-for="scanGroup in scanGroups"
                        :key="scanGroup.id"
                            class="mb-3"
                    >
                        <v-expansion-panel-header>
                            <div class="d-flex flex-column mr-3">
                                <p class="text-h6">{{scanGroup.name}}</p>
                                <v-progress-linear
                                    :value="createPercentage(scanGroup.totalScanned, scanGroup.totalItems)"
                                    color="purple"
                                    class="mt-2"
                                ></v-progress-linear>

                                <div class="ml-auto text-overline grey--text text--lighten-1" v-if="scanGroup.totalItems">
                                    {{scanGroup.totalScanned}}/{{scanGroup.totalItems}}
                                </div>
                            </div>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <v-sparkline
                                :value="scanGroup.history"
                                :gradient="['#9c27b0', '#00a1bd']"
                                :smooth="5"
                                :line-width="1"
                                :padding="5"
                                auto-draw
                                ></v-sparkline>


                                <v-list two-line>
                                <v-list-item
                                    v-for="item in getItemsForScanGroup(scanGroup)"
                                    :key="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold">
                                            {{item.name}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-progress-linear
                                                :value="createPercentage(item.totalScanned, item.totalItems)"
                                                color="cyan"
                                                class="mt-2"
                                            ></v-progress-linear>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle class="d-flex mt-1">
                                            <div class="ml-auto" v-if="item.totalItems">{{item.totalScanned}}/{{item.totalItems}}</div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
let refreshTimer;

import apiClient from "@/api/apiClient";

export default {
    name: "Information",
    mounted() {
        this.loadAppInfo();
        refreshTimer = window.setInterval(() => {
            this.loadStatistics();
        }, 30000)
    },
    onBeforeDestroy() {
        window.clearInterval(refreshTimer);
    },
    data: () => ({
        app: null,
        data: null,
        expandedPanels: null
    }),
    watch: {
        $route() {
            this.loadAppInfo()
        }
    },
    computed: {
        eventId() {
            return parseInt(this.$route.params.id);
        },
        scanGroups() {
            return this.data?.scanGroups ?? null;
        },
        items() {
            return this.data?.items ?? null;
        }
    },
    methods: {
        loadAppInfo() {
            this.$store.dispatch('getAppInfo', {id: this.eventId, type: 'entry_status'}).then(app => {
                this.app = app;
                this.$store.commit('UPDATE_HEADER', {
                    title: app.title,
                    browserTitle: 'Statistieken '+app.title
                })
            }).then(() => {
                this.loadStatistics();
            })
        },
        getItemsForScanGroup(scanGroup) {
            return this.data.items.filter(i => scanGroup.itemIds.includes(i.id));
        },
        async loadStatistics() {

            return apiClient.get(`/events/${this.eventId}/statistics`)
                .then(response => {
                    if (this.expandedPanels === null) {
                        this.expandedPanels = response.data.scanGroups
                            .filter(g => g.isActive)
                            .map(g => g.id);
                    }

                    this.data = response.data
                })
        },

        refreshFromHeader() {
            this.loadStatistics();
        },
        createPercentage(current, total) {
            if (total === 0) {
                return 0;
            } else {
                return (current / total) * 100;
            }
        }
    }
}
</script>

